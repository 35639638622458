import React from "react"

const activeItem = (inc, currentPage) => {
  if (inc === currentPage) {
    return "page-item active"
  } else {
    return "page-item"
  }
}

const MiddleLinks = ({ currentPage, totalPages, pageLink }) => {
  const displayLinks = 7
  const midLink = Math.ceil(displayLinks / 2)

  let startPage
  let endPage
  let pageLinks = []

  if (currentPage - midLink < 1) {
    if (totalPages < displayLinks) {
      startPage = 0
      endPage = totalPages
    } else {
      startPage = 0
      endPage = displayLinks
    }
  }

  if (currentPage - midLink >= 1) {
    if (currentPage + midLink > totalPages) {
      startPage = totalPages - displayLinks
      endPage = totalPages
    } else {
      startPage = currentPage - midLink
      endPage = currentPage + midLink - 1
    }
  }

  for (let i = startPage; i < endPage; i++) {
    pageLinks.push(
      <li className={activeItem(i + 1, currentPage)} key={i}>
        <a
          className="page-link"
          href={i === 0 ? `${pageLink}` : `${pageLink}/${i + 1}`}
        >
          {i + 1}
        </a>
      </li>
    )
  }

  return pageLinks
}

export default MiddleLinks
