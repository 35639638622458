import React from "react"

import FirstLink from "./FirstLink"
import MiddleLinks from "./MiddleLinks"
import LastLink from "./LastLink"

import "./index.scss"

const Pagination = ({
  currentPage,
  itemsPerPage,
  totalItems,
  totalPages,
  pageLink,
}) => {
  if (totalItems <= itemsPerPage) {
    return null
  } else {
    return (
      <div className="row">
        <div className="col-12">
          <ul className="pagination justify-content-center">
            <FirstLink pageLink={pageLink} />
            <MiddleLinks
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              totalPages={totalPages}
              pageLink={pageLink}
            />
            <LastLink pageLink={`${pageLink}/${totalPages}`} />
          </ul>
        </div>
      </div>
    )
  }
}

export default Pagination
