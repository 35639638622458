import React from "react"

const FirstLink = ({ pageLink }) => (
  <li className="page-item">
    <a className="page-link" href={pageLink}>
      <span aria-hidden="true">&laquo;</span>
    </a>
  </li>
)

export default FirstLink
