import React from "react"

const LastLink = ({ pageLink }) => {
  return (
    <li className="page-item">
      <a className="page-link" href={pageLink}>
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  )
}

export default LastLink
