import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Pagination from "../components/Pagination"

const competitionsFiles = `${process.env.FILE_BUCKET}/competitions`

const Competitions = ({ location, pageContext }) => {
  const {
    competitions,
    limit,
    skip,
    numCompetitionPages,
    currentPage,
  } = pageContext
  const pageLink = "/competitions"

  return (
    <Layout location={location}>
      <SEO
        title="Competitions"
        description="We run regular competitions for our readers. Try your luck!"
      />
      <div className="row">
        <div className="col text-center news-heading">
          <h1>Competitions</h1>
          <p>We run regular competitions for our readers. Try your luck!</p>
        </div>
      </div>
      <div className="row">
        {competitions.slice(skip, skip + limit).map((competition) => (
          <div className="col-md-6 article" key={competition.id}>
            {competition.image && (
              <img
                src={`${competitionsFiles}/${competition.image}`}
                alt={competition.title}
                className="competition-image"
              />
            )}
            <h3>{competition.title}</h3>
            <p>{competition.description}</p>
            <Link to={`/competition/${competition.id}`}>
              <Button variant="primary" bsPrefix="cln">
                More Info
              </Button>
            </Link>
          </div>
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        itemsPerPage={limit}
        totalItems={competitions.length}
        totalPages={numCompetitionPages}
        pageLink={pageLink}
      />
    </Layout>
  )
}

export default Competitions
